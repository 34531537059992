import React, { useState, useEffect } from "react";
import StatusBar from "./StatusBar";
import ResultBar from "./ResultTable";
import Measurement from "./Measurment";
import { GetTotalQualities, GetQualityColorHex } from "../js/KpiQuality";

import { VictoryPie, VictoryLabel, VictoryChart, VictoryBar } from "victory";

import { CaretRightFilled, CheckCircleFilled } from "@ant-design/icons";
import {
  Statistic,
  Progress,
  Row,
  Col,
  Divider,
  Button,
  Badge,
  Tag,
  DatePicker,
} from "antd";
import { Doughnut } from "react-chartjs-2";

import "./../App.css";
import axios from "axios";

const { RangePicker } = DatePicker;

function SideBar(props) {
  const [MaxBins, UpdateMaxBins] = useState([]);
  const [Bands, UpdateBands] = useState([]);
  const [Loading, UpdateLoading] = useState(0);

  async function GetBands() {
    UpdateLoading(1);
    let response = await axios.post("https://api.revolutionapp.io/api/qos/metadata/coordinates",
    {
      operator: props.selectedoperator,
      countryiso: props.filters.countryiso,
      date: props.filters.date,
      coordinates: props.filters.coordinates
    });
    if(response.data.length){
      UpdateBands(response.data);
    }
    UpdateLoading(0);
  }

  useEffect(() => {
    GetBands();
  }, [props.selectedoperator]);

  let hexCount = [];
  useEffect(() => {
    let operatorsArray = [];
    let uniquesOperators = [];
    if (props.hexagons.length) {
      props.hexagons.map((d, x) => {
        operatorsArray.push(d.operator);
      });

      uniquesOperators = new Set(operatorsArray);
      let result = {};
      for (var i = 0; i < operatorsArray.length; ++i) {
        if (!result[operatorsArray[i]]) result[operatorsArray[i]] = 0;
        ++result[operatorsArray[i]];
      }

      const sortable = Object.entries(result).sort((a, b) => b[1] - a[1]);

      UpdateMaxBins(sortable);
    }
  }, [props.hexagons]);

  let colorScheme = ["#DE3163", "#FF7F50", "#40E0D0", "cyan", "navy"];
  return (
    <>
      <div
        className="side-bar"
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff",
          zIndex: 5,
          position: "relative",
          height: "100vh",
          overflow: "auto",
          paddingTop: "80px",
        }}
      >
        <Row justify="center">

          <Col span={24}>
            {" "}
            <div>
              <br />
              <h1>{props.selectedoperator}</h1>
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          
          <div
            style={{
              width: "80%",
              marginLeft: "0%",
              textAlign: "left",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              {" "}
              <h2>{props.title}</h2>
              <p style={{ fontSize: "10px", color: "  #99a3a4  " }}>
                Numbers of good ,poor and bad hex bins
              </p>
              <br></br>
            </Col>
            <Col span={24}>
              {
                GetTotalQualities(props.selectedmeasurement).map((x, i) => (
                  <>
                  <Row>
                    <Col span={12}>
                      {" "}
                      <span>
                        <Tag color={GetQualityColorHex(x)}>
                          <b>{x}</b>{" "}
                        </Tag>
                      </span>
                    </Col>
                    <Col span={12}>{props.hexagons.filter(hex => hex.Quality == x).length}/{props.hexagons.length}</Col>
                    <Col span={24}>
                      <br></br>
                    </Col>
                  </Row>
                  </>
                ))
              }
            </Col>
          </div>

          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            {" "}
              <div id="chart">
              {MaxBins.length ? (
                <>
                  <div
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      textAlign: "center",
                    }}
                  >
                    <>
                      <h2> Network Meta Data</h2>
                      <div>
                        <br></br>
                      </div>
                      {" "}
                      {Loading ? (
                        <>
                        <div>
                          <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <Button type="default" shape="round" loading>
                                Loading Meta Data
                              </Button>
                            </Col>
                          </Row>
                        </div>{" "}
                      </>
                      ):(

                      Bands.map((x, i) => (
                        <Row>
                        <Col span={3}>
                          <b>{x.Technology}</b>
                        </Col>
                        <Col span={13}>{x.Band}</Col>
                        <Col span={8}>{x.Records}</Col>
                      </Row>
                      ))
                      )}
                    </>
                  </div>
                </>
              ) : (
                <></>
              )}
              </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SideBar;
