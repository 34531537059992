import React, { useState, useEffect } from "react";

import GoogleSearch from "./GoogleSearch";
import {
  Divider,
  Button,
  DatePicker,
  Tooltip,
  Switch,
  Collapse,
  Radio,
  Row,
  Col,
  Select,
} from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import moment from "moment";
import "./../App.css";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;
function SideBarRight(props) {
  const [Comparison, UpdateComparison] = useState(false);
  const [TimeSlot, UpdateTimeSlot] = useState(null);
  const [SingleCSPView, UpdateSingleCSPView] = useState(false);
  const history = useHistory();
  let login = localStorage.getItem("login");
  if (!login) {
    history.push("./login");
  }

  function GetKIPData(kpi) {
    props.selectmeasurement(kpi);
  }

  function GetHexResolution(hex) {
    props.selectresolution(hex);
  }

  function FetchResults() {
    props.call(true);
  }

  function Reset() {
    props.updatefilters({
      city: null,
      countryiso: null,
      operator: null,
      measurement: null,
      date: {
        first: null,
        second: null,
      },
      call: false,
    });
    let dates = { ...props.comparison };
    dates.first = null;
    dates.second = null;
    props.updatecomparson(dates);
    props.updatereset(true);
  }

  function CompareDates(e) {
    let dates = { ...props.comparison };
    dates.first = e[0]._d;
    dates.second = e[1]._d;
    props.updatecomparson(dates);
  }

  useEffect(() => {
    if (!Comparison) {
      let dates = { ...props.comparison };
      dates.first = null;
      dates.second = null;
      props.updatecomparson(dates);
      props.updatecompare(false);
    }
  }, [Comparison]);
  const dateFormat = "YYYY-MM-DD";

  function ViewType(e) {
    if (e === "singlecsp") {
      UpdateSingleCSPView(true);
    } else {
      UpdateSingleCSPView(false);
    }
  }

  return (
    <>
      <>
        <div
          className=""
          style={{
            zIndex: 100,
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "70px",
            left: "0px",
            top: "0px",
            backgroundColor: "white",
            width: "100%",

            boxShadow: " 10px 0px 5px #aaaaaa",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <>
            <Row
              justify="left"
              items="center"
              align="left"
              style={{ width: "100%" }}
              gutter={20}
            >
              <Col
                span={4}
                align="left"
                flex={1}
                style={{ textAlign: "left", cursor: "pointer" }}
              >
                <img
                  src="truemeasure.png"
                  style={{
                    height: "33px",
                    float: "left",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    // Reset();
                    window.location.reload(false);
                  }}
                ></img>
              </Col>
              <Col span={4}>
                <GoogleSearch
                  updatefilters={props.updatefilters}
                  updatepolygon={props.updatepolygon}
                  updatecenter={props.updatecenter}
                  filters={props.filters}
                  reset={props.reset}
                />
              </Col>
              <Col span={2}>
                <Tooltip title="search">
                  <Button
                    style={{
                      float: "left",
                      height: "34px",
                      padding: "5px 10px 5px 10px",
                      // backgroundColor: "transparent",
                      border: "none",
                      color: "#fff",
                      // fontSize: "20px",
                      border: "none",
                      // fontWeight: "900",
                      // display: "flex",
                      marginLeft: "-20px",
                      border: "none",
                    }}
                    size="medium"
                    type="primary"
                    icon={
                      <SearchOutlined
                        // color="#3FB9D0"
                        color="#333333"
                        style={{
                          fontWeight: "900",
                        }}
                      />
                    }
                    loading={props.loading}
                    onClick={() => {
                      FetchResults();
                    }}
                  >
                    Search
                  </Button>
                </Tooltip>
              </Col>
              {props.hexagons > 0 ? (
                <>
                  {" "}
                  <Col span={3}>
                    <Select
                      placeholder="Select Mode"
                      // optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e == "benchmark") {
                          props.selectoperator(null);
                        }
                        props.selectmode(e);
                        ViewType(e);
                      }}
                      defaultActiveFirstOption={true}
                      defaultValue={props.selectedmode}
                    >
                      <Option value="singlecsp">Single Service Provider</Option>
                      <Option value="benchmark">Benchmark</Option>
                    </Select>
                  </Col>
                  <Col span={3}>
                    <>
                    <Select placeholder="Hex Resolution"
                        style={{ width: "100%" }}
                        defaultValue={props.selectedresolution}
                        defaultActiveFirstOption={false}
                        onChange={(e) => GetHexResolution(e)}
                      >
                        <Option value="7">Level 7</Option>
                        <Option value="8">Level 8</Option>
                        <Option value="9">Level 9</Option>
                    </Select>
                    </>
                  </Col>
                  <Col span={3}>
                    <>
                      <Select
                        placeholder="Select KPI"
                        style={{ width: "100%" }}
                        defaultValue={props.selectedmeasurement}
                        defaultActiveFirstOption={true}
                        onChange={(e) => GetKIPData(e)}
                      >
                        <Option value="ping">Latency</Option>
                        <Option value="speed dl">Throughput DL</Option>
                        <Option value="speed ul">Throughput UL</Option>
                        <Option value="ss_rsrp">Coverage 5G</Option>
                        <Option value="rsrp">Coverage LTE</Option>
                        <Option value="4g_5g_rsrp">Coverage LTE + 5G</Option>
                      </Select>
                    </>
                  </Col>
                  <Col span={3}>
                    {SingleCSPView ? (
                      <>
                        {" "}
                        <Select
                          placeholder="Select an operator"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            props.selectoperator(e);
                          }}
                        >
                          {
                            props.operators.map( (op) => 
                              <option key={op}>{op}</option> )
                          }
                        </Select>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </>
        </div>
      </>
    </>
  );
}
export default SideBarRight;
