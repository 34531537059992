import React, { useState, useEffect } from "react";
import StatusBar from "./StatusBar";
import ResultBar from "./ResultTable";
import Measurement from "./Measurment";

import { VictoryPie, VictoryLabel, VictoryChart, VictoryBar } from "victory";

import { CaretRightFilled, CheckCircleFilled } from "@ant-design/icons";
import { Statistic, Progress, Row, Col, Divider, Button } from "antd";
import { Doughnut } from "react-chartjs-2";
import { getOperatorColorHex } from "../js/Operator";

import "./../App.css";

function SideBar(props) {
  const [MaxBins, UpdateMaxBins] = useState([]);
  let hexCount = [];
  useEffect(() => {
    let operatorsArray = [];
    let uniquesOperators = [];
    if (props.hexagons.length) {
      props.hexagons.map((d, x) => {
        if(d.operator != "")
          operatorsArray.push(d.operator);
      });

      uniquesOperators = new Set(operatorsArray);
      let result = {};
      for (var i = 0; i < operatorsArray.length; ++i) {
        if (!result[operatorsArray[i]]) result[operatorsArray[i]] = 0;
        ++result[operatorsArray[i]];
      }

      const sortable = Object.entries(result).sort((a, b) => b[1] - a[1]);

      UpdateMaxBins(sortable);
    }
  }, [props.hexagons]);

  let colorScheme = ["#00A8E0", "#FF7F50", "#40E0D0", "cyan", "navy"];
  return (
    <>
      <div
        className="side-bar"
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff",
          zIndex: 5,
          position: "relative",
          height: "100vh",
          overflow: "auto",
          paddingTop: "80px",

          // width: "300px",
        }}
      >
        <Row justify="center">
          <Col span={24}>
            {" "}
            <br></br>
            <h2>Ranking</h2>
          </Col>

          <Col span={24}>
            {" "}
            <div id="chart">
              {MaxBins.length ? (
                <>
                  <div>
                    {/* <br></br> */}
                    <CheckCircleFilled
                      size={30}
                      style={{ fontSize: "50px", color: "#3FB9D0" }}
                    />
                    <h1> {MaxBins[0][0]} </h1> wins in <b>{MaxBins[0][1]} </b>{" "}
                    out of <b>{MaxBins.reduce((sum, pair) => sum + pair[1], 0)}</b> bins
                  </div>
                  <br></br>
                  <br></br>
                  <div
                    style={{
                      // padding: "0px 20px 0px 20px",
                      width: "80%",
                      marginLeft: "10%",
                      textAlign: "left",
                    }}
                  >
                    {MaxBins.map((i, x) => (
                      <>
                        <Row>
                          <Col span={12}>{i[0]}</Col>
                          <Col span={6}>{i[1]} </Col>
                          <Col span={6}>
                            {" "}
                            {((i[1] * 100) / props.hexagons.filter((obj) => obj.operator !== "").length).toFixed(
                              2
                            )}{" "}
                            %{" "}
                          </Col>
                          <Col span={24}>
                            <Divider />
                          </Col>
                        </Row>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Col>

          <Col span={24}>
            <br></br>{" "}
          </Col>

          <Col span={24}>
            {" "}
            <h2>{props.title}</h2>
            <br></br>
          </Col>
          <Col span={20}>
            {props.avg.length ? (
              <>
                {props.avg.map((i, index) => (
                  <>
                    <Row>
                      <Col span={12}>
                        {" "}
                        <div>{i._id}</div>
                      </Col>
                      <Col span={12}>
                        {" "}
                        <div>
                          {i.avg.toFixed(2)} {props.guage}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {" "}
                        <div
                          style={{
                            marginTop: "10px",
                            width: `${(i.avg.toFixed(2) * 100) / props.max}%`,
                            height: "10px",
                            maxWidth: "100%",
                            //backgroundColor: colorScheme[index],
                            backgroundColor: getOperatorColorHex(i._id),
                          }}
                        ></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {" "}
                        <div>
                          <br></br>
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SideBar;
