import React, {
  useState,
  useEffect
} from "react";
import Sidebar from "./components/SideBar";
import SideBarNew from "./components/SideBarNew";
import SideBarNew2 from "./components/SideBarNew2";
import CSPSideBar from "./components/CSPSideBar";
import RightSidebar from "./components/InfoBarRight";
import KPIBar from "./components/Kpis";
import Map from "./components/Map";
import ComparisonMap from "./components/ComparisonMap";
import Login from "./components/Login";
import Wait from "./components/Wait";
import OperatorMap from "./components/MapOperator";
import "./App.css";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Skeleton,
  notification,
  Button,
  Switch as SW
} from "antd";
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {
  Hex6,
  Hex7,
  Hex8
} from "./data/latency";
import moment from "moment";
import axios from "axios";
import { diffProps } from "@deck.gl/core/dist/es5/lifecycle/props";
import { GetQuality } from "./js/KpiQuality";

function App() {
  const URL = "https://api.revolutionapp.io/api/qos/";
  // const URL = "http://localhost:3004/";

  const [Cities, UpdateCities] = useState([]);
  const [SelectedCity, UpdateSelectedCity] = useState(null);
  const [SelectedOperator, UpdateSelectedOperator] = useState("");
  const [SelectedMeasure, UpdateSelectedMeasure] = useState("4g_5g_rsrp");
  const [SelectedMode, UpdateSelectedMode] = useState("benchmark");
  const [SelectedResolution, UpdateSelectedResolution] = useState("8")

  const [Title, UpdateTitle] = useState("Download Speed");
  const [Guage, UpdateGuage] = useState("Mbp/s");
  // const [ActiveTests, UpdateActiveTests] = useState(0);

  const [View, UpdateView] = useState(1);
  const [AllHex, UpdateAllHex] = useState([]);
  const [InterOperator, UpdateInterOperator] = useState([]);
  const [InterOperatorHex, UpdateInterOperatorHex] = useState([]);
  const [OperatorAvg, UpdateOperatorAvg] = useState([]);
  const [StatsBar, UpdateStatsBar] = useState(false);
  const [ActiveTests, UpdateActiveTests] = useState(0);
  const [HexSix, UpdateHexSix] = useState([]);
  const [HexSeven, UpdateHexSeven] = useState([]);
  const [MaxNumber, UpdateMaxNumber] = useState(25);
  const [HexEight, UpdateHexEight] = useState([]);
  const [ComparisonDate, UpdateComparisonDate] = useState({
    first: null,
    second: null,
  });
  const [Operators, UpdateOperators] = useState([]);
  const [Compare, UpdateCompare] = useState(false);
  const [ZoomLevel, UpdateZoomLevel] = useState(9);
  const [SelectedHexagons, UpdateSelectedHexagons] = useState([]);
  const [Polygon, UpdatePolygon] = useState([]);
  const [Center, UpdateCenter] = useState([-122.4,37.7]);
  const [Reset, UpdateReset] = useState(false);

  const [CallApi, UpdateCallApi] = useState(false);
  const [HexComaparison, UpdateHexComparison] = useState({
    IncreasedBins: [],
    DecreasedBins: [],
    NewBins: [],
    TotalBins: 0,
  });
  const [Loading, UpdateLoading] = useState(false);
  const [Filters, UpdateFilters] = useState({
    city: null,
    operator: null,
    measurement: "DLThroughput",
    date: {
      first: moment(new Date(), "YYYY-MM-DD").subtract(24, "month"),
      second: moment(new Date(), "YYYY-MM-DD").add(1, "month"),
    },
    call: false,
    coordinates: [],
  });

  const openNotificationWithIcon = (title, message, type) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  function switchChange(e) {
    console.log(e);
    UpdateView(e);
  }

  async function getDataFromApi() {
    if (!Polygon.length) {
      // openNotificationWithIcon(
      //   "No Area!",
      //   "Please select a location!",
      //   "error"
      // );
    } else if (!Filters.date) {
      // openNotificationWithIcon(
      //   "Select Date",
      //   "Please select date range to proceed",
      //   "info"
      // );
    } else {
      Filters.coordinates = Polygon;
      //Filters.operator = "All";
      Filters.operator = SelectedOperator;
      Filters.measurement = SelectedMeasure;
      Filters.resolution = SelectedResolution;

      UpdateLoading(true);
      let coordinates = [];
      Polygon.map((i, x) => {
        coordinates.push([i[1], i[0]]);
      });

      //queries
      let hexDataOperatorComparison = await axios.post(
        URL + "inter-operator",
        Filters
      );

      if (hexDataOperatorComparison.data && hexDataOperatorComparison.data.data && hexDataOperatorComparison.data.avg) {
        UpdateInterOperatorHex(hexDataOperatorComparison.data.data);
        UpdateInterOperator(hexDataOperatorComparison.data.data);
        UpdateOperatorAvg(hexDataOperatorComparison.data.avg);
        UpdateActiveTests(
          hexDataOperatorComparison.data.avg.reduce(
            (n, {
              samples
            }) => n + samples,
            0
          )
        );
        if(Filters.operator == "All" || Filters.operator == "" || Filters.operator == null){
          UpdateOperators(hexDataOperatorComparison.data.avg.map(op => op._id));
        }
        else{
          for(let row of hexDataOperatorComparison.data.data){
            if(isNaN(row.info[0].average)){
              row.Quality = GetQuality(SelectedMeasure, row.info[0].average.split('|')[1]);
            }
            else {
              row.Quality = GetQuality(SelectedMeasure, row.winner);
            }
          }
        }
        UpdateZoomLevel(12);
      }
      else{
        openNotificationWithIcon(
          "No Data",
          "Please search with other options!",
          "info"
        );
      }
      UpdateLoading(false);
    }
  }

  useEffect(() => {
    if (Reset) {
      UpdatePolygon([]);
      UpdateOperators([]);
      // UpdateHexagons([]);
      UpdateHexComparison({
        IncreasedBins: [],
        DecreasedBins: [],
        NewBins: [],
        TotalBins: 0,
      });
      UpdateReset(false);
    }
  }, [Reset]);

  useEffect(async () => {
    if (CallApi) {

      //make hex call for API
      await getDataFromApi();

      UpdateCallApi(false);
    }
  }, [CallApi]);

  useEffect(() => {
    if(SelectedResolution !== "")
      getDataFromApi();
  }, [SelectedResolution])

  useEffect(() => {
    if(SelectedResolution !== "")
      getDataFromApi();
  }, [SelectedOperator])

  useEffect(() => {
    let title = "";
    let guage = "";
    switch (SelectedMeasure) {
      case "ping":
        title = "Latency";
        guage = "ms";
        break;
      case "speed dl":
        title = "Download Speed";
        guage = "Mbps";
        break;
      case "speed ul":
        title = "Upload Speed";
        guage = "Mbps";
        break;
      case "ss_rsrp":
        title = "5G Coverage";
        guage = "dBm";
        break;
      case "rsrp":
        title = "LTE Coverage";
        guage = "dBm";
        break;
      case "4g_5g_rsrp":
        title = "Coverage (LTE + 5G)";
        guage = "dBm";
        break;
      default:
        title = "";
        guage = "";
        break;
    }
    UpdateTitle(title);
    UpdateGuage(guage);
    if (title != "")
      getDataFromApi();
  }, [SelectedMeasure]);

  return (
    <>
      {/* {Cities.length === 0 ? (
        <Skeleton active paragraph={{ rows: 10 }} title={true} />
      ) : ( */}
      <>
        <BrowserRouter>
          <Switch>
            <Route path="/operators">
              <OperatorMap 
                hexagons={InterOperator ?? []}
                countryiso={Filters.countryiso}
                date={Filters.date} />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <RightSidebar
                filters={Filters}
                updatefilters={UpdateFilters}
                cities={Cities}
                updatepolygon={UpdatePolygon}
                updatecenter={UpdateCenter}
                polygon={Polygon}
                operators={Operators ?? []}
                selectmeasurement={UpdateSelectedMeasure}
                selectresolution={UpdateSelectedResolution}
                selectoperator={UpdateSelectedOperator}
                selectmode={UpdateSelectedMode}
                selectedresolution={SelectedResolution}
                selectedmeasurement={SelectedMeasure}
                selectedoperator={SelectedOperator}
                selectedmode={SelectedMode}
                selectcity={UpdateSelectedCity}
                loading={Loading}
                hexagons={InterOperator ? InterOperator.length : 0}
                comparison={ComparisonDate}
                updatecomparson={UpdateComparisonDate}
                call={UpdateCallApi}
                updatecompare={UpdateCompare}
                compare={Compare}
                updatereset={UpdateReset}
                reset={Reset}
              />
              <Row>
                {InterOperator && InterOperator.length ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 6,
                        left: "18%",
                        top: "20px",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          // zIndex: 4,
                          left: "5%",
                          zIndex: 101,
                          bottom: "20px",
                        }}
                      >
                        {SelectedMode == "benchmark" ? (
                          <>
                            {" "}
                            <Button
                              size="default"
                              type="primary"
                              // backgroundColor="#3FB9D0"
                              // shape="round"
                              style={{
                                backgroundColor: "#3FB9D0",
                                outline: "none",
                                border: "none",
                              }}
                              onClick={() => UpdateStatsBar(!StatsBar)}
                            >
                              Sampling Statistics
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {StatsBar ? (
                        <>
                          <div>
                            <div
                              style={{
                                backgroundColor: "black",
                                opacity: ".7",
                                height: "100vh",
                                width: "100%",
                                left: "0px",
                                top: "0px",
                                position: "fixed",
                                zIndex: 10,
                              }}
                            ></div>
                            <SideBarNew
                              tests={ActiveTests}
                              bar={StatsBar}
                              updatebar={UpdateStatsBar}
                            />
                          </div>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Col span={4} className="pointer">
                      {" "}
                      {SelectedMode == "benchmark" ? (
                        <>
                          <SideBarNew2
                            avg={OperatorAvg}
                            hexagons={InterOperator ?? []}
                            selectedmeasurement={SelectedMeasure}
                            selectedoperator={SelectedOperator}
                            selectedmode={SelectedMode}
                            title={Title}
                            guage={Guage}
                            max={MaxNumber}
                          />
                        </>
                      ) : (
                        <> {/* <KPIBar /> */}</>
                      )}
                      {SelectedMode == "singlecsp" ? (
                        <>
                          <CSPSideBar
                            avg={OperatorAvg}
                            filters={Filters}
                            hexagons={InterOperator ?? []}
                            selectedmeasurement={SelectedMeasure}
                            selectedoperator={SelectedOperator}
                            selectedmode={SelectedMode}
                            title={Title}
                            guage={Guage}
                            max={MaxNumber}
                          />
                        </>
                      ) : (
                        <> {/* <KPIBar /> */}</>
                      )}
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col span={InterOperatorHex && InterOperatorHex.length ? 18 : 20}>
                  <OperatorMap
                    lasthexagons={InterOperatorHex ?? []}
                    hexagons={InterOperator ?? []}
                    updatehexagons={UpdateInterOperatorHex}
                    avg={OperatorAvg ?? []}
                    selectedmeasurement={SelectedMeasure}
                    selectedoperator={SelectedOperator}
                    selectedmode={SelectedMode}
                    updatezoomlevel={UpdateZoomLevel}
                    zoomlevel={ZoomLevel}
                    countryiso={Filters.countryiso}
                    center={Center}
                    date={Filters.date}
                  />
                </Col>
              </Row>
            </Route>
          </Switch>
        </BrowserRouter>{" "}
      </>
    </>
  );
}

export default App;