let operators = [{
    Name: "AT&T Wireless Inc.",
    ColorHex: "0050C8"
},
{
    Name: "Verizon Wireless",
    ColorHex: "96050A"
},
{
    Name: "T-Mobile",
    ColorHex: "D21E96"
},
{
    Name: "IAM/Itissallat",
    ColorHex: "006EB9"
},
{
    Name: "INWI/WANA",
    ColorHex: "AE1B85"
},
{
    Name: "Orange/Medi Telecom",
    ColorHex: "FF6600"
},
{
    Name: "Ufone",
    ColorHex: "F8801F"
},
{
    Name: "Telenor",
    ColorHex: "0AC0F5"
},
{
    Name: "Jazz",
    ColorHex: "EC3F3F"
},
{
    Name: "Zong",
    ColorHex: "8BC53A"
}]
function GetOperatorColorRGB(operator) {
    let record = operators.filter(op => op.Name === operator);
    if(record.length === 1)
        return [parseInt(record[0].ColorHex.substring(0,2), 16),parseInt(record[0].ColorHex.substring(2,4), 16),parseInt(record[0].ColorHex.substring(4,6), 16), 180];
    else
        return [0,0,0,180];
}

function getOperatorColorHex(operator) {
    let record = operators.filter(op => op.Name === operator);
    if(record.length === 1)
        return `#${record[0].ColorHex}`;
    else
        return `#000000`;
}

export {
    GetOperatorColorRGB,
    getOperatorColorHex
}