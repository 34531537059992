import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
// import "./index.css";
import { Form, Input, Button, Checkbox, Row, Col, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const NormalLoginForm = () => {
  const history = useHistory();
  const onFinish = (values) => {
    localStorage.setItem("login", 15);
    if (values.Email == "admin@enablersinc.com" && values.password == "admin") {
      history.push("/");
    } else {
      notification.open({
        message: "Please check credentials",
        description: "Please recheck your email and password then try again.",
      });
    }
  };

  function routeChange() {
    let path = `newPath`;
    this.props.history.push(path);
  }
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row style={{ width: "1000px" }}>
        {" "}
        <Col span={24}>
          <div
            style={{
              //   backgroundColor: "#f7f7f7",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form
              style={{ width: "90%" }}
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              {/* <h2>truemeasures.io</h2> */}
              <img
                src="revolution2.png"
                style={{ height: "20px", filter: `invert("100%") ` }}
              />
              <br></br>
              <br></br>
              <Form.Item
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onSubmit={() => history.push("/")}
                >
                  Log in
                </Button>
                {/* Or <a href="">register now!</a> */}
                <br></br>
                <br></br>
                <span style={{ color: "#979696" }}>by Enablers Inc</span>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NormalLoginForm;
