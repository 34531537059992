import React, { useState, useEffect } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Statistic, Progress, Row, Col, Divider, Button, Select } from "antd";

const { Option } = Select;
function KpiBar(props) {
  return (
    <>
      <div
        className="side-bar"
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff",
          zIndex: 7,
          position: "relative",
          height: "100vh",
          overflow: "auto",
          width: "100%",
          padding: "0px 20px 0px 20px",
        }}
      >
        <Row justify="center">
          <Col span={24}>
            {" "}
            <br></br>
            <h2>Single CSP View</h2>
            <br></br>
          </Col>
          <Col span={24}>
            <Select placeholder="Select an operator" style={{ width: "100%" }}>
              <Option value="SingleCSP">ATT</Option>
              <Option value="lucy">VZW</Option>
              <Option value="lucy">TMo</Option>
            </Select>
          </Col>
          <Col span={24}>
            {" "}
            <br></br>
          </Col>
          <Col span={24}>
            <Select placeholder="Select kpi" style={{ width: "100%" }}>
              <Option value="jack">Latency</Option>
              <Option value="lucy">5G Coverage</Option>
              <Option value="Yiminghe">DL Throughput</Option>
              <Option value="Yiminghe">POLQA</Option>
            </Select>
          </Col>

          <Col span={24}>
            {" "}
            <br></br>
          </Col>
          <Col span={24} align="right">
            <Button type="primary" icon={<SearchOutlined />}>
              Search
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default KpiBar;
