import React, { useEffect, useState } from "react";
import ComaprisonMap from "./ComparisonMap";
import { getOperatorColorHex } from "../js/Operator";
import {
  Card,
  Typography,
  Row,
  Col,
  Rate,
  Divider,
  Progress,
  Statistic,
  Button,
  Tag,
} from "antd";
import "./../App.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import axios from "axios";
const { Title, Text } = Typography;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
function GetGuage(measurement){
  let guage;
  switch (measurement) {
    case "ping":
      guage = "ms";
      break;
    case "speed dl":
    case "speed ul":
      guage = "Mbps";
      break;
    case "ss_rsrp":
    case "rsrp":
    case "4g_5g_rsrp":
      guage = "dBm";
      break;
    default:
      guage = "";
      break;
  }
  return guage;
}
function BinCard(props) {
  const [HexMap, UpdateHexMap] = useState(0);
  const [Loading, UpdateLoading] = useState(0);
  const [PointsData, UpdatePointsData] = useState([]);
  let colorScheme = ["#DE3163", "#FF7F50", "#40E0D0", "cyan", "navy"];
  const URL = "https://api.revolutionapp.io/api/qos/";
  async function ShowPoints() {
    UpdateHexMap(!HexMap);
    if (!HexMap) {
      let response = await axios.post(
        // "https://api.revolutionapp.io/api/qos/points/hex",
        "https://api.revolutionapp.io/api/qos/points/hex",
        // "http://localhost:3004/points/hex",
        {
          hex: props.info.id,
          kpi: props.kpi,
          operator: props.operator,
          countryiso: props.countryiso,
          date: props.date
        }
      );
      if (response.data) {
        UpdatePointsData(response.data);
        UpdateLoading(0);
        UpdateHexMap(0);
      } else {
        UpdateLoading(0);
        UpdateHexMap(0);
      }
    }
    else {
      UpdateLoading(0);
    }
  }

  useEffect(() => {
    UpdateLoading(1);
    ShowPoints();
  }, [props.info.id]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "500px",
          overflow: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <div style={{ flex: 1 }}>
          {/* <Row>
            <Col span={24} style={{ padding: "20px 10px 20px 10px" }}>
              <Title level={5}>
                <ArrowLeftOutlined onClick={() => props.card(false)} />
              </Title>
            </Col>
          </Row> */}
          <Row>
            <Col span={24} style={{ textAlign: "left" }}>
              {props.info.hex.length ? (
                <>
                  <br></br>
                  {props.mode == "benchmark" ? (
                    <>
                      {props.info.hex.map((h, i) => (h && h.operator && h.samples && h.average? (
                        <>
                          <Row
                            style={{
                              padding: "0px 30px 0px 30px",
                              marginBottom: "5px",
                            }}
                          >
                            <Col span="5">
                              <Text strong>{h.operator.split(' ')[0].toUpperCase()}</Text>
                            </Col>
                            <Col span="3">
                              <text>{h.samples}</text>
                            </Col>
                            <Col span="16">
                              {" "}
                              <div
                                style={{
                                  // marginTop: "10px",
                                  width: `${((isNaN(h.average) ? parseFloat(h.average.split('|')[0]) : h.average).toFixed(2) * 100) / 20}%`,
                                  height: "20px",
                                  borderRadius: "0px",
                                  backgroundColor: getOperatorColorHex(h.operator),
                                  color: "white",
                                  textAlign: "left",
                                  paddingLeft: "20px",
                                  fontSize: "12px",
                                  minWidth: "30%",
                                  maxWidth: "100%",
                                }}
                              >
                                <b>{(isNaN(h.average) ? parseFloat(h.average.split('|')[0]) : h.average).toFixed(2)} {GetGuage(props.kpi)}</b>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <>
                        <Row
                          style={{
                            padding: "0px 30px 0px 30px",
                            marginBottom: "5px",
                          }}
                        >
                          <Col span="5">
                            <Text strong>
                              {props.operator
                                ? props.operator.toUpperCase()
                                : ""}
                            </Text>
                          </Col>
                          <Col span="3">
                            <text>{props.info.hex[0].samples}</text>
                          </Col>
                          <Col span="16">
                            {" "}
                            <div
                              style={{
                                // marginTop: "10px", 
                                width: `${
                                  ((isNaN(props.info.hex[0].average) ? parseFloat(props.info.hex[0].average.split('|')[0]) : props.info.hex[0].average).toFixed(2) * 100) / 20
                                }%`,
                                height: "20px",
                                borderRadius: "0px",
                                backgroundColor: colorScheme[0],
                                color: "white",
                                textAlign: "left",
                                paddingLeft: "20px",
                                fontSize: "12px",
                                minWidth: "30%",
                                maxWidth: "100%",
                              }}
                            >
                              <b>{(isNaN(props.info.hex[0].average) ? parseFloat(props.info.hex[0].average.split('|')[0]) : props.info.hex[0].average).toFixed(2)} {GetGuage(props.kpi)}</b>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Divider />
          <Row style={{ padding: "0px 30px 0px 30px" }}>
            <Col span={10}>
              <Text strong>Service Providers</Text>
            </Col>
            <Col span={14}>
              {props.mode == "benchmark" ? (
                <>
                  {props.info.hex.map((h, i) => (
                    <>
                      {i>0 ? <br />: ""}
                      <Text strong style={{ marginLeft: `0px` }}>
                        {h.operator}
                      </Text>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Text strong style={{ marginLeft: "10px" }}>
                    {props.operator ? props.operator.toUpperCase() : ""}
                  </Text>
                </>
              )}
            </Col>
          </Row>
              <Row style={{ padding: "0px 30px 0px 30px" }}>
                <Col span={10}>
                  <Text strong>Carriers</Text>
                </Col>
                <Col span={14}>
          {!Loading ? (
            <>
                  {PointsData.bands && PointsData.bands.lte && PointsData.bands.lte.length > 0 ? (
                      <Tag style={{ marginBottom: "5px" }}>
                        LTE {PointsData.bands.lte.join(",")}
                      </Tag>)
                    : (<></>)}
                  {PointsData.bands && PointsData.bands.fiveG && PointsData.bands.fiveG.length > 0 ? (
                    <Tag style={{ marginBottom: "5px" }}>
                      5G {PointsData.bands.fiveG.join(",")}
                    </Tag>)
                    : (<></>)}
              </>)
              :(
                <></>
              )}
              </Col>
              </Row>
              <Divider />
              {/* <Row style={{ padding: "0px 30px 0px 30px" }}>
                    <Col span={10}>
                      <Text strong>Confidence Interval</Text>
                    </Col>
                    <Col span={14}>
                      <Text>80%</Text>
                    </Col>
                  </Row> */}
            <div style={{ flex: 2 }}>
                {" "}
                {Loading ? (
                  <>
                    <div>
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <Button type="default" shape="round" loading>
                            Loading Bin Map
                          </Button>
                        </Col>
                      </Row>
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}
                {!Loading && PointsData && PointsData.data ? (
                  <>
                    <Row>
                      {/* <Col span={24} style={{ textAlign: "center" }}>
                        <Divider />
                      </Col> */}
                      <Col span={24} style={{ textAlign: "left", paddingLeft: "30px" }}>
                        <Statistic title=" Active Tests" value={PointsData.data.length} />
                      </Col>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <br></br>
                      </Col>
                      <Col span={24}>
                        <ComaprisonMap hexagons={PointsData.data} hex={props.info.id} guage={GetGuage(props.kpi)} />
                      </Col>
                    </Row></>
                ) : (
                  <></>
                )}
              </div>
      </div>
      {/* <Card bordered={true} style={{ width: 500 }}> */}
    </div>
      {/* </Card> */}
    </>
  );
}
export default BinCard;
