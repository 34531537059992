// Bins must be defined in desending mode
const latency = [{
        Max: Infinity,
        Min: 300,
        Category: "Bad",
        Order: 4
    },
    {
        Max: 300,
        Min: 100,
        Category: "Poor",
        Order: 3
    },
    {
        Max: 100,
        Min: 50,
        Category: "Good",
        Order: 2
    },
    {
        Max: 50,
        Min: 0,
        Category: "Excellent",
        Order: 1
    }
]

const throughputDL = [{
        Max: Infinity,
        Min: 30,
        Category: "Excellent",
        Order: 1
    },
    {
        Max: 30,
        Min: 20,
        Category: "Good",
        Order: 3
    },
    {
        Max: 20,
        Min: 10,
        Category: "Poor",
        Order: 2
    },
    {
        Max: 10,
        Min: 0,
        Category: "Bad",
        Order: 1
    }
]

const throughputUL = [{
        Max: Infinity,
        Min: 30,
        Category: "Excellent",
        Order: 1
    },
    {
        Max: 30,
        Min: 20,
        Category: "Good",
        Order: 3
    },
    {
        Max: 20,
        Min: 10,
        Category: "Poor",
        Order: 2
    },
    {
        Max: 10,
        Min: 0,
        Category: "Bad",
        Order: 1
    }
]

const coverage5G = [{
    Max: 0,
    Min: -60,
    Category: "Excellent",
    Order: 1
},
{
    Max: -60,
    Min: -85,
    Category: "Good",
    Order: 3
},
{
    Max: -85,
    Min: -100,
    Category: "Poor",
    Order: 2
},
{
    Max: -100,
    Min: -140,
    Category: "Bad",
    Order: 1
}
]

const coverage4G = [{
    Max: 0,
    Min: -60,
    Category: "Excellent",
    Order: 1
},
{
    Max: -60,
    Min: -85,
    Category: "Good",
    Order: 3
},
{
    Max: -85,
    Min: -100,
    Category: "Poor",
    Order: 2
},
{
    Max: -100,
    Min: -140,
    Category: "Bad",
    Order: 1
}
]

const coverage4G5G = [{
    Category: "5G",
    Order: 1
},
{
    Category: "LTE",
    Order: 2
}
]

function GetQuality(kpi, val) {
    let selector;
    switch (kpi) {
        case "ping":
            selector = latency;
            break;
        case "speed dl":
            selector = throughputDL;
            break;
        case "speed ul":
            selector = throughputDL;
            break;
        case "ss_rsrp":
            selector = coverage5G;
            break;
        case "rsrp":
            selector = coverage4G;
            break;
        case "4g_5g_rsrp":
            return val;
    }
    if (selector)
        for (let set of selector) {
            if (val < set.Max && val >= set.Min)
                return set.Category;
        }
    return "Unknown"
}

function GetTotalQualities(kpi) {
    let result = [];
    switch (kpi) {
        case "ping":
            result = latency.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
        case "speed dl":
            result = throughputDL.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
        case "speed ul":
            result = throughputUL.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
        case "ss_rsrp":
            result = coverage5G.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
        case "rsrp":
            result = coverage4G.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
        case "4g_5g_rsrp":
            result = coverage4G5G.sort((a, b) => a.Order > b.Order ? 1 : -1).map(v => v.Category)
            break;
    }
    result.push("Unknown");
    return result;
}

function GetQualityColorHex(quality) {
    switch (quality) {
        case "Excellent":
        case "5G":
            return "#0AFF0F";
        case "Good":
            return "#1ABC38";
        case "Bad":
            return "#5B8D75";
        case "Poor":
        case "LTE":
            return "#0E6655";
        case "Unknown":
            return "#000000";
    }
}

function GetQualityColorRGB(quality) {
    switch (quality) {
        case "Excellent":
        case "5G":
            return [10, 255, 15, 180];
        case "Good":
            return [26, 188, 156, 180];
        case "Bad":
            return [19, 141, 117, 180];
        case "Poor":
        case "LTE":
            return [14, 102, 85, 180];
        case "Unknown":
            return [0, 0, 0, 180];
    }
}

export {
    GetQuality,
    GetTotalQualities,
    GetQualityColorHex,
    GetQualityColorRGB
}