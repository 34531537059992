import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";

import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import BinCard from "./ComparisonCard";
import { Drawer } from "antd";
import { h3ToGeo, h3ToGeoBoundary } from "h3-js";
import { Row, Col } from "antd";
import { GetQualityColorRGB } from "../js/KpiQuality";
import { getOperatorColorHex, GetOperatorColorRGB } from "../js/Operator";


export default function Map(props) {
  const Confidence = ["High", "Low"];
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 2,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.44,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([-122.4194, 37.7749]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [SCard, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const [Visible_Six, UpdateVisible_Six] = useState(false);
  const [Visible_Seven, UpdateVisible_Seven] = useState(false);
  const [Visible_Eight, UpdateVisible_Eight] = useState(false);
  const [CurrentZoom, UpdateCurrentZoom] = useState(0);
  const [HexInfo, UpdateHexInfo] = useState({
    id: null,
    average: null,
    count: null,
    percentage: null,
    oldVal: null,
  });

  const [initialViewState, setInitialViewState] = useState({
    longitude: LatLng[0],
    latitude: LatLng[1],
    zoom: 8,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
  });

  function RangeBox(color) {
    return {
      height: "15px",
      width: "100px",
      backgroundColor: color,
    };
  }
  function selectHexagons(hexid) {
    let boundryCoords = h3ToGeoBoundary(hexid._id);
    UpdateHexInfo({
      id: hexid.hex,
      hex: hexid.info,
    });
    UpdateCard(true);
  }

  
function getOperatorColor(operator, quality) {
  if (!props.selectedoperator) {
    //return [0,0,0]
    return GetOperatorColorRGB(operator);
  } else {
    return GetQualityColorRGB(quality);
  }
}

  function createhexLayer(data) {
    // if()
    if (data) {
      if (data.length) {
        const hexLayer = new H3HexagonLayer({
          id: "hex",
          data,
          pickable: true,
          wireframe: false,
          filled: true,
          // coverage: 0.999, //spaces between hexagons
          elevationScale: Elevation,
          opacity: 0.7,

          getHexagon: (d) => d.hex,

          getFillColor: (d) =>
            d.color ? d.color : getOperatorColor(d.operator, d.Quality),
          material,

          onClick: (d) => {
            selectHexagons(d.object);
          },
          updateTriggers: {
            getFillColor: (d) => d.color,
            getColor: (d) => d.color,
          },
          transitions: {
            getFillColor: 100,
          },
        });

        // let hexCenter = h3ToGeo(data[data.length - 1]._id);
        // setInitialViewState({
        //   longitude: hexCenter[1],
        //   latitude: hexCenter[0],
        //   zoom: props.zoomlevel,
        //   maxZoom: 17,
        //   pitch: 60,
        //   bearing: 10,
        //   pitch: Elevation ? 70 : 0,
        //   bearing: Elevation ? 0 : 0,
        // });
        // UpdateLatLng(hexCenter);
        UpdateDGLayer([hexLayer]);
      }
    }
  }

  /**
   * Other
   */

  useEffect(() => {
    if (props.reset) {
      setInitialViewState({
        longitude: LatLng[0],
        latitude: LatLng[1],
        zoom: 1,
        maxZoom: 20,
        pitch: 0,
        bearing: 0,
      });
      createhexLayer([]);
      UpdateCard(false);
    }
  }, [props.reset]);

  // useEffect(() => {
  //   if (props.reset) {
  //     setInitialViewState({
  //       longitude: LatLng[0],
  //       latitude: LatLng[1],
  //       zoom: 1,
  //       maxZoom: 20,
  //       pitch: 0,
  //       bearing: 0,
  //     });
  //     createhexLayer([]);
  //     UpdateCard(false);
  //   } else {
  //     let CurrentZoom = props.zoomlevel;

  //     if (CurrentZoom >= 13) {
  //       // if (SelectedMode === "banchmark") {

  //       createhexLayer(props.hexagons);
  //       // } else {
  //       //   UpdateInterOperator(Hexagons);
  //       // }
  //     } else if (CurrentZoom < 13 && CurrentZoom >= 11) {
  //       // if (SelectedMode === "banchmark") {

  //       createhexLayer(props.z7);
  //       // } else {
  //       // UpdateInterOperator(Hexagons);
  //       // }
  //     } else if (CurrentZoom < 11) {
  //       // if (SelectedMode === "banchmark") {
  //       createhexLayer(props.z6);
  //       // } else {
  //       // UpdateInterOperator(Hexagons);
  //       // }
  //     }
  //   }
  // }, [
  //   props.hexagons,
  //   props.z6,
  //   props.z7,
  //   props.selectedmode,
  //   props.selectedmode,
  // ]);

  useEffect(() => {
    UpdateLayer(props.zoomlevel);
  }, [
    props.hexagons,
    props.selectedmeasurement,
    props.selectedoperator,
    props.zoomlevel,
  ]);

  function stateChangeFunc(changeState) {
    if (props.hexagons.length) {
      let { oldViewState, viewState } = { ...changeState };
      props.updatezoomlevel(viewState.zoom);

      let CurrentZoom = viewState.zoom;
      let OldZoom = oldViewState.zoom;

      UpdateLayer(CurrentZoom);
    }
  }

  function GetGuage(measurement){
    let guage;
    switch (measurement) {
      case "ping":
        guage = "ms";
        break;
      case "speed dl":
      case "speed ul":
        guage = "Mbps";
        break;
      case "ss_rsrp":
      case "rsrp":
      case "4g_5g_rsrp":
        guage = "dBm";
        break;
      default:
        guage = "";
        break;
    }
    return guage;
  }

  function UpdateLayer(CurrentZoom) {
    if (props.hexagons.length) {
      // if (CurrentZoom >= 13) {
        // if (SelectedMode === "banchmark") {
        props.updatehexagons(props.lasthexagons);
        createhexLayer(props.hexagons);
        // } else {
        //   UpdateInterOperator(Hexagons);
        // }
      // } else if (CurrentZoom < 13 && CurrentZoom >= 5) {
      //   // if (SelectedMode === "banchmark") {
      //   props.updatehexagons(props.z8);
      //   createhexLayer(props.z8);
      //   // } else {
      //   // UpdateInterOperator(Hexagons);
      //   // }
      // } else if (CurrentZoom < 5) {
      //   // if (SelectedMode === "banchmark") {
      //   props.updatehexagons(props.z7);
      //   createhexLayer(props.z7);
      //   // } else {
      //   // UpdateInterOperator(Hexagons);
      //   // }
      // }
    }
  }

  return (
    <>
      <div>
        {" "}
        {props.hexagons.length ? (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: 10,
                left: "570px",
                // marginRight: "30%",
                top: "20px",
                color: "white",
                display: "none",
              }}
            >
              {props.coverage > 0 ? <></> : <></>}
            </div>{" "}
            <div
              style={{
                position: "fixed",
                zIndex: 2,
                bottom: props.selectedmode == "benchmark" ? "20px" : "50px",
                left: props.selectedmode == "benchmark" ? "18%" : "1%",
                // width: "300px",
              }}
            >
              {" "}
              {props.selectedmode == "benchmark" ? (
                <>
                  {" "}
                  {/* <Row>
                    <Col span={8} order={1}>
                      <div style={RangeBox("rgba(222, 49, 99)")}></div>
                    </Col>
                    <Col span={8} order={2}>
                      <div style={RangeBox("rgba(255, 127, 80)")}></div>
                    </Col>
                    <Col span={8} order={3}>
                      <div style={RangeBox("rgba(86, 171, 145)")}></div>
                    </Col>
                  </Row> */}
                  {props.avg.length ? (
                    <>
                      <Row
                        style={{
                          color: "#f7f7f7",
                          fontSize: ".8em",
                          textAlign: "center",
                        }}
                      >
                        {props.avg.map((d, i) => (
                          <>
                            <Col span={8} order={1}>
                              <div style={RangeBox(getOperatorColorHex(d._id))}></div>
                            </Col>
                          </>
                        ))}
                      </Row>{" "}
                    </>
                  ) : (
                    <> </>
                  )}
                  {props.avg.length ? (
                    <>
                      <Row
                        style={{
                          color: "#f7f7f7",
                          fontSize: ".8em",
                          textAlign: "center",
                        }}
                      >
                        {props.avg.map((d, i) => (
                          <>
                            <Col span={8} order={1}>
                              {d._id}
                            </Col>
                          </>
                        ))}
                      </Row>{" "}
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <Row>
                    <Col span={8} order={3}>
                      <div style={RangeBox("rgba(26, 188, 156)")}></div>
                    </Col>
                    <Col span={8} order={2}>
                      <div style={RangeBox("rgba(19, 141, 117)")}></div>
                    </Col>
                    <Col span={8} order={1}>
                      <div style={RangeBox("rgba(14, 102, 85)")}></div>
                    </Col>
                  </Row>
                  <>
                    <Row
                      style={{
                        color: "#f7f7f7",
                        fontSize: ".8em",
                        textAlign: "center",
                      }}
                    >
                      <Col span={8} order={1}>
                        Good
                      </Col>
                      <Col span={8} order={1}>
                        Better
                      </Col>
                      <Col span={8} order={1}>
                        Poor
                      </Col>
                    </Row>{" "}
                  </>
                </>
              )}
            </div>{" "}
          </>
        ) : (
          <> </>
        )}
        <div className="groupcard" style={{}}>
          <Drawer
            title="San Francisco"
            placement="right"
            onClose={() => {
              UpdateCard(!SCard);
            }}
            visible={SCard}
            width={500}
            // mask={true}
            // maskStyle={{
            //   position: "fixed",
            //   // zIndex: 10,
            //   left: "0px",
            //   top: "0px",
            //   backgroundColor: "#000000",
            //   opacity: 0.6,
            //   height: "100vh",
            // }}
            // headerStyle={{ backgroundColor: "#f3f3f3", height: "70px" }}
            bodyStyle={{
              overflow: "hidden",
              padding: "0px",
              backgroundColor: "#fff",
            }}
            // style={{ width: "500px", overflow: "hidden" }}
          >
            <BinCard
              info={HexInfo}
              card={UpdateCard}
              data={props.hexagons}
              mode={props.selectedmode}
              operator={props.avg ? props.avg.map(a => a._id).join(",") : ""}
              kpi={props.selectedmeasurement}
              countryiso={props.countryiso}
              date={props.date}
            />
          </Drawer>
          {/* {SCard ? (
            <BinCard info={HexInfo} card={UpdateCard} data={props.hexagons} />
          ) : (
            <></>
          )} */}
        </div>
        <DeckGL
          style={{ position: "fixed", zIndex: 1, left: "00px", top: "0px" }}
          // initialViewState={initialViewState}
          initialViewState={{
            longitude: props.center[0],
            latitude: props.center[1],
            zoom: 10,
            bearing: 0,
            pitch: 0
          }}
          controller={true}
          effects={[Ambient]}
          layers={DGLayer}
          // onViewStateChange={stateChangeFunc}
          // getTooltip={({ object }) =>
          //   object && ` ${object.color} and ${object.average} `
          // }
          getTooltip={({ object }) =>
            object && {
              html: `<h2 style="color:#fff;font-weight:"bold">${
                object.operator
              }  </h2> 
              <p> Samples available for ${object.info.length} Operator(s)<p>
              ${props.selectedmeasurement}: ${object.winner.toFixed(2)} ${GetGuage(props.selectedmeasurement)}`,
              style: {
                backgroundColor: "#333",
                // fontSize: "0.8em",
                color: "#fff",
              },
            }
          }
        >
          <ReactMapGL
            reuseMaps
            // mapStyle={mapStyle}
            longitude={LatLng[0]}
            latitude={LatLng[1]}
            preventStyleDiffing={true}
            transitionDuration={1000}
            transitionInterpolator={new FlyToInterpolator()}
            //mapStyle="mapbox://styles/mapbox/dark-v10"
            // mapStyle={
            //   Map
            //     ? "mapbox://styles/mapbox/dark-v10"
            //     : "mapbox://styles/mapbox/satellite-streets-v11"
            // }
            // "mapbox://styles/mapbox/outdoors-v11"
            mapStyle="mapbox://styles/mapbox/dark-v10"
            //`mapbox://styles/mapbox/satellite-v9`
            // mapStyle="mapbox://styles/mapbox/outdoors-v11"
            // mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxApiAccessToken="pk.eyJ1Ijoic2FsbWFuanZkIiwiYSI6ImNrc3JjaXFlZzBsanUybnRmZHFhNGpmdmYifQ.cYc--QH_zO99dPIsBMSAcQ"
          />
        </DeckGL>
      </div>
    </>
  );
}
